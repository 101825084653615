import * as React from 'react';
import { map as _map } from 'lodash';
import Heading from '@lce/slice_v2/Elements/Heading';
import Text from '@lce/slice_v2/Elements/Text';
import Box from '@lce/slice_v2/Layout/Box';
import { IInstruction } from '@lce/intl-types/src/IInstruction';
import { Image } from '@lce/slice_v2';
import Spear from './assets/spear-divider.png';

interface IInstructions {
  instructions: IInstruction[];
}

const Instructions: React.FC<IInstructions> = ({ instructions }) => (
  <>
    {_map(instructions, (instruction: IInstruction, index) => (
      <Box key={index}>
        {index > 0 && <Image src={ Spear } sx={ { width: 200 }} />}
        <Heading as='h2' sx={{ mt: 40, textTransform: 'uppercase' }}>{instruction.heading}</Heading>
        {instruction.subheading && <Text sx={{ fontSize: 10, marginBottom: 32 }}>{instruction.subheading}</Text>}
      </Box>
    ))}
  </>
);

export default Instructions;
