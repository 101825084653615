import * as React from 'react';
import IconButton from '@lce/slice_v2/Elements/IconButton';
import Flex from '@lce/slice_v2/Layout/Flex';
import Link from '@lce/slice_v2/Elements/Link';
import Heading from '@lce/slice_v2/Elements/Heading';
import Text from '@lce/slice_v2/Elements/Text';
import Modal from '@lce/slice_v2/Patterns/Modal';
import { X } from 'react-feather';
import { SxStyleProp } from 'theme-ui';

import * as Styled from './LeavingSiteModal.style';

export interface ILeavingSiteModalProps {
  onClose: () => void;
  url: string;
  testId?: string;
  styledProps?: SxStyleProp;
  content?: ILeavingSiteModalContent;
  isOpen: boolean;
}

export interface ILeavingSiteModalContent {
  title: string;
  legalText: string;
}

const LeavingSiteModal: React.FC<ILeavingSiteModalProps> = ({
  url,
  styledProps,
  onClose,
  content,
  testId,
  isOpen,
  children,
}) => (
  <Modal
    closeIcon={false}
    data-testid={`${testId ? `${testId}-` : ''}leavingSiteModal`}
    isOpen={isOpen}
    onClose={onClose}
    sx={Styled.Modal}
  >
    <Flex sx={Styled.CloseButtonWrapper}>
      <IconButton data-testid={`${testId ? `${testId}-` : ''}closeButton`} onClick={onClose} sx={{ cursor: 'pointer' }} variant="skeleton">
        <X color="black" size={32} />
      </IconButton>
    </Flex>
    <Flex sx={Styled.ContentWrapper}>
      <Heading sx={Styled.Title}>
        {content?.title}
      </Heading>
      <Link
        data-testid={`${testId ? `${testId}-` : ''}link`}
        href={url}
        sx={styledProps || Styled.Link}
        target="_blank"
        variant="skeleton"
      >
        {children}
      </Link> 
      <Text sx={Styled.LegalText}>
        {content?.legalText}
      </Text>
    </Flex>
  </Modal>
);

export default LeavingSiteModal;
