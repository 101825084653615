import * as React from 'react';
import useSiteConfig from '@lce/gatsby-theme-multi-site/src/api/Config/useSiteConfig';
import usePageSeo from '@lce/gatsby-theme-multi-site/src/api/PageSeo/usePageSeo';
import useLayout from '@lce/gatsby-theme-multi-site/src/api/Layout';
import { IDefaultPageProps } from '@lce/intl-types/src/IDefaultPageProps';
import Box from '@lce/slice_v2/Layout/Box';

import SelectFranchisee from '../SelectFranchisee';
import { useSelectedFranchiseeId } from '../../api/FranchiseeId/hooks/useSelectedFranchiseeId';
import Layout from '../Layout/Layout';

export interface IConnectedLayoutProps extends IDefaultPageProps {
  showStartYourOrder?: boolean;
}

const ConnectedLayout: React.FC<IConnectedLayoutProps> = (props) => {
  const { locale } = props.pathContext;
  const pageSeo = usePageSeo(props);
  const layoutProps = useLayout(locale);
  const { direction } = useSiteConfig(locale);
  const { franchiseeId } = useSelectedFranchiseeId(locale);
  const [ loading, setLoading ] = React.useState(true);

  React.useEffect(() => setLoading(false), []);

  return franchiseeId ? (
    <Layout {...props} {...pageSeo} {...layoutProps} direction={direction} >
      {props.children}
    </Layout>
  ) : loading ? <Box /> : <SelectFranchisee {...props} />;
};

export default ConnectedLayout;
