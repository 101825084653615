const defaultTextStyles = {
  fontSize: 16,
  fontWeight: 500,
};

export default {
  header: {
    title: {
      fontFamily: 'primary',
      color: 'primary',
      fontWeight: 'bold',
      fontSize: 10,
    },
    divider: {
      color: 'primary',
      fontSize: 10,
      fontWeight: '300',
    },
  },
  card: {
    default: {
      marginBottom: 10,
      ...defaultTextStyles,
    },
    sectionTitle: {
      marginBottom: 12,
      paddingTop: 20,
      fontSize: 32,
      fontWeight: 900,
      fontFamily: 'secondary',
    },
    spacebetween: {
      ...defaultTextStyles,
      marginRight: 20,
      marginBottom: 20,
      ':last-child': {
        marginRight: 0,
      },
    },
    groupTitle: {
      ...defaultTextStyles,
      marginBottom: 1,
      color: 'halfblack',
    },
    listItem: {
      fontFamily: 'secondary',
    },
    small: {
      fontSize: 5,
      fontFamily: 'secondary',
    },
  },
};
