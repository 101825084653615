import { SxStyleProp } from 'theme-ui';

export const Modal: SxStyleProp = {
  maxWidth: '700px',
  marginTop: '120px',
  width: ['90%', '100%', '100%'],
};

export const CloseButtonWrapper: SxStyleProp = { 
  justifyContent: 'flex-end',
};

export const ContentWrapper: SxStyleProp = {
  flexDirection: 'column',
  padding: ['15px', '50px'],
  textAlign: 'center',
  gap: '20px',
};

export const Title: SxStyleProp = {
  textTransform: 'uppercase',
  fontSize: ['20px', '20px', '24px'],
  lineHeight: '1',
};

export const LegalText: SxStyleProp = {
  fontSize: '12px',
  lineHeight: '1.2',
  textAlign: 'center',
  m: '16px auto',
  fontFamily: 'tertiary',
  color: '#4e4f51',
};

export const Link: SxStyleProp = {
  borderRadius: 0,
  alignItems: 'center',
  py: '12px',
  px: '20px',
  mx: 'auto',
  textAlign: 'center',
  textDecoration: 'none',
  fontFamily: 'primary',
  fontSize: ['20px', '20px', '24px'],
  textTransform: 'uppercase',
  lineHeight: 1,
  fontWeight: 'bold',
  color: 'white',
  backgroundColor: 'primary',
  cursor: 'pointer',
};

