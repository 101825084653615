import { useEffect, useRef } from 'react';

export const useOnClickOutside = (cb: any) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const isolatedCb = (e: any) => {
      if ( ref.current && !ref.current.contains(e.target)) {
        cb();
      }
    };

    document.addEventListener('mousedown', isolatedCb);

    return () => {
      document.removeEventListener('mousedown', isolatedCb);
    };
  }, [ cb ]);

  return ref;
};
