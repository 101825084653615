import * as React from 'react';
import Helmet from 'react-helmet';

export interface ISeoProps {
  title?: string;
  description?: string;
}

const Seo: React.FC<ISeoProps> = ({ title, description }) => title || description ? (
  <Helmet
    meta={[
      {
        content: description,
        name: `description`,
      },
      {
        content: description,
        property: `og:description`,
      },
      {
        content: title,
        property: `og:title`,
      },
    ]}
    title={title}
  />
) : null;

export default Seo;
