import * as React from 'react';

import { SocialIcon } from 'react-social-icons';
import { theme } from '../../../../../../theme';

export interface IIconProps {
  network?: string;
  url?: string;
}

const Icon: React.FC<IIconProps> = ({ network, url }) => (
  <SocialIcon
    bgColor={ theme.colors.black }
    data-testid={ url }
    network={ network }
    style={ { width: 40, height: 40 } }
    url={ url }
  />
);

export default Icon;
