export default {
  primary: {
    color: 'primary',
    textDecoration: 'none',
    ':hover': {
      textDecoration: 'underline',
    },
  },
  blackBox: {
    color: 'black',
    borderColor: 'black',
    background: 'primary',
    '&:hover': {
      color: 'black',
    },
  },
  legal: {
    color: 'primary',
    textDecoration: 'underline',
  },
  mainNav: {
    background: 'transparent',
    color: 'black',
    cursor: 'pointer',
    fontFamily: 'primary',
    fontWeight: 'bold',
    textDecoration: 'none',
    textTransform: 'uppercase',
    position: 'relative',
    ':visited': {
      color: 'black',
    },
    ':hover': {
      textDecoration: 'underline',
    },
  },
};
