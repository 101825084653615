export default {
  primary: {
    minWidth: 120,
    padding: '12px 20px',
    fontFamily: 'secondary',
    fontSize: 7,
    lineHeight: '1',
  },
  skeleton: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'transparent',
    color: 'primary',
    padding: 0,
  },
  menuItemNavToggle: {
    background: 'transparent',
    color: 'black',
    position: 'absolute',
    bottom: '0',
    alignSelf: 'flex-end',
    paddingRight: '16px',
  },
  treeNav: {
    color: 'primary',
    backgroundColor: '#f0f0f0',
    borderRadius: '0',
    outline: 'none',
    width: '56px',
    minWidth: '0',
    padding: '16px',
    lineHeight: '1',
    ':hover': {
      color: 'white',
      backgroundColor: 'primary',
    },
  },
  breadCrumbsButton: {
    lineHeight: '1',
    height: '44px',
    marginLeft: '28px',
    marginRight: '24px',
  },
  header: {
    mode: {
      fontFamily: 'primary',
      background: 'none',
      border: 'none',
      padding: '0',
      margin: '0',
      color: 'black',
      fontWeight: '900',
      fontSize: 8,
      px: '8px',
    },
  },
  card: {
    info: {
      background: 'none',
      border: 'none',
      color: 'black',
      display: 'inline-block',
      verticalAlign: 'text-bottom',
      lineHeight: '1',
      padding: '0',
      marginLeft: '4px',
      width: '16px',
      height: '16px',
    },
  },
};
