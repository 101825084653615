import * as React from 'react';
import Flex from '@lce/slice_v2/Layout/Flex';
import Heading from '@lce/slice_v2/Elements/Heading';

import SocialIcons from './SocialIcons';
import * as Styled from './Social.style';

export interface ISocialProps {
  text: string;
  socialMediaLinks: string[];
}

const Social: React.FC<ISocialProps> = ({ text, socialMediaLinks }) => (
  <Flex sx={Styled.Social}>
    { text && <Heading as="h2" sx={Styled.SocialText}>{ text }</Heading> }
    <SocialIcons urls={ socialMediaLinks } />
  </Flex>
);

export default Social;
