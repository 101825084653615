import * as React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Box from '@lce/slice_v2/Layout/Box';

import MdxProvider from '@lce/intl-ui/src/Provider/MdxProvider';
import Container from '../../Layout/Container';
import FooterNavigation, { IFooterNavigationProps } from './components/FooterNavigation';
import LanguageSelector, { ILanguage } from './components/LanguageSelector';

export interface IFooterProps extends IFooterNavigationProps {
  copyrightStartingYear: string;
  languages?: ILanguage[];
  legalText: string;
  socialMediaLinks: string[];
  socialMediaText: string;
  subLegalText: string;
}

const Footer: React.FC<IFooterProps> = ({ copyrightStartingYear, languages, legalText, socialMediaLinks, socialMediaText, subLegalText, ...props }) => (
  <Container data-testid="footer" sx={{ display: 'flex', alignItems: 'flex-start', px: '16px' }}>
    <Box sx={{ width: '100%' }}>
      <FooterNavigation socialMediaLinks={socialMediaLinks} socialMediaText={socialMediaText} {...props} />
      <LanguageSelector languages={languages} locale={props.locale} />
      <Box
        sx={{
          pb: 5,
          width: '100%',      
        }}
      >
        <Box sx={{ fontFamily: 'tertiary', fontSize: 7, display: 'block' }}>
          ©{copyrightStartingYear}-{new Date().getFullYear()} {legalText}
        </Box>
        {subLegalText &&
          <Box sx={{ fontFamily: 'tertiary', fontSize: 7, display: 'block' }}>
            <MdxProvider>
              <MDXRenderer>{subLegalText}</MDXRenderer>
            </MdxProvider>
          </Box>
        }
      </Box>
    </Box>
  </Container>
);

export default Footer;
