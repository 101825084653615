import * as React from 'react';
import Heading from '@lce/slice_v2/Elements/Heading';
import Link from '@lce/slice_v2/Elements/Link/Link';
import Box from '@lce/slice_v2/Layout/Box';

const H1: React.FC = ({ children, ...props }) => (
  <Heading as="h1" {...props} data-testid="Headline1" sx={{ fontSize: 16 }}>
    {children}
  </Heading>
);

const H2: React.FC = ({ children, ...props }) => (
  <Heading as="h2" {...props} data-testid="Headline2" sx={{ fontSize: 14 }}>
    {children}
  </Heading>
);

const H3: React.FC = ({ children, ...props }) => (
  <Heading as="h3" {...props} data-testid="Headline3" sx={{ fontSize: 12 }}>
    {children}
  </Heading>
);

const H4: React.FC = ({ children, ...props }) => (
  <Heading as="h4" {...props} data-testid="Headline4" sx={{ fontSize: 10 }}>
    {children}
  </Heading>
);

const H5: React.FC = ({ children, ...props }) => (
  <Heading as="h5" {...props} data-testid="Headline5" sx={{ fontSize: 8 }}>
    {children}
  </Heading>
);

const H6: React.FC = ({ children, ...props }) => (
  <Heading as="h6" {...props} data-testid="Headline6" sx={{ fontSize: 6 }}>
    {children}
  </Heading>
);

const Table = (tableProps: any) => (
  <Box data-testid="Table" sx={{ overflowX: 'auto' }}>
    <table {...tableProps} />
  </Box>
);

interface IObjectIndexer<T> {
  [key: string]: T;
}

const DefaultComponents: IObjectIndexer<React.FC<any>> = {
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  h6: H6,
  // eslint-disable-next-line react/display-name
  a: (props: any) => <Link data-testid="Anchor" sx={{ color: 'primary' }} {...props} />,
  table: Table,
};

export default DefaultComponents;
