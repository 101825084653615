import mreavesLightEot from './mreaves/mreavesxlsannarr-020415006EmigreWebOnly.eot';
import mreavesLightWoff from './mreaves/mreavesxlsannarr-020415006EmigreWebOnly.woff';
import mreavesLightWoff2 from './mreaves/mreavesxlsannarr-020415006EmigreWebOnly.woff2';
import mreavesLightTtf from './mreaves/mreavesxlsannarr-020415006EmigreWebOnly.ttf';

import mreavesItalicEot from './mreaves/mreavesxlsannarri-020415006EmigreWebOnly.eot';
import mreavesItalicWoff from './mreaves/mreavesxlsannarri-020415006EmigreWebOnly.woff';
import mreavesItalicWoff2 from './mreaves/mreavesxlsannarri-020415006EmigreWebOnly.woff2';
import mreavesItalicTtf from './mreaves/mreavesxlsannarri-020415006EmigreWebOnly.ttf';

import mreavesBoldEot from './mreaves/mreavesxlsannaru-020415006EmigreWebOnly.eot';
import mreavesBoldWoff from './mreaves/mreavesxlsannaru-020415006EmigreWebOnly.woff';
import mreavesBoldWoff2 from './mreaves/mreavesxlsannaru-020415006EmigreWebOnly.woff2';
import mreavesBoldTtf from './mreaves/mreavesxlsannaru-020415006EmigreWebOnly.ttf';

import lcScriptEot from './LCscript/LCScriptWeb-Regular.eot';
import lcScriptWoff from './LCscript/LCScriptWeb-Regular.woff';
import lcScriptWoff2 from './LCscript/LCScriptWeb-Regular.woff2';
import lcScriptTtf from './LCscript/LCScriptTT-Regular.ttf';

const families = {
  mrEaves: '\'Mr Eaves\'',
  lcScript: '\'LC Script\'',
};

const fontDefinitions = `
  @font-face {
    font-family: ${families.mrEaves};
    src: url(${mreavesBoldEot});
    src: url('${mreavesBoldEot}#iefix') format('embedded-opentype'),
        url('${mreavesBoldWoff2}') format('woff2'),
        url('${mreavesBoldWoff}') format('woff'),
        url('${mreavesBoldTtf}') format('ttf');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: ${families.mrEaves};
    src: url(${mreavesItalicEot});
    src: url('${mreavesItalicEot}#iefix') format('embedded-opentype'),
        url('${mreavesItalicWoff2}') format('woff2'),
        url('${mreavesItalicWoff}') format('woff'),
        url('${mreavesItalicTtf}') format('ttf');
    font-weight: normal;
    font-style: italic;
  }

  @font-face {
    font-family: ${families.mrEaves};
    src: url(${mreavesLightEot});
    src: url('${mreavesLightEot}#iefix') format('embedded-opentype'),
        url('${mreavesLightWoff2}') format('woff2'),
        url('${mreavesLightWoff}') format('woff'),
        url('${mreavesLightTtf}') format('ttf');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: ${families.lcScript};
    src: url(${lcScriptEot});
    src: url('${lcScriptEot}#iefix') format('embedded-opentype'),
        url('${lcScriptWoff2}') format('woff2'),
        url('${lcScriptWoff}') format('woff'),
        url('${lcScriptTtf}') format('ttf');
    font-weight: normal;
    font-style: normal;
  }

  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
`;

export default fontDefinitions;
