import { graphql, useStaticQuery } from 'gatsby';

import franchiseeFilter from '@lce/intl-util/src/FranchiseeFilter';
import { useSelectedFranchiseeId } from '@lce/gatsby-theme-multi-site/src/api/FranchiseeId/hooks/useSelectedFranchiseeId';

interface IDatoOrderingModalProps {
  pickupModal: INodePickupModalProps;
  deliveryModal: INodeDeliveryModalProps;
  directionsModal: INodeDirectionsModalProps;
}

interface INodePickupModalProps {
  nodes: IPickupModalProps[];
}

interface INodeDeliveryModalProps {
  nodes: IDeliveryModalProps[];
}

interface INodeDirectionsModalProps {
  nodes: IDirectionsModalProps[];
}

export interface IPickupModalProps {
  title: string;
  legalText: string;
}

export interface IDeliveryModalProps {
  title: string;
  legalText: string;
  selectDeliveryOptionLabel: string;
  pickupButtonLabel: string;
  cancelButtonLabel: string;
}

export interface IOrderingModalProps {
  deliveryModal: {
    title: string;
    legalText: string;
  };
  pickupModal: IPickupModalProps;
  deliveryOptionsModal: IDeliveryModalProps;
  directionsModal: IDirectionsModalProps;
}

export interface IDirectionsModalProps {
  title: string;
  legalText: string;
  bodyText: string;
  confirmButtonText: string;
  cancelButtonText: string;
}

// eslint-disable-next-line max-lines-per-function
const useOrderingModal = (locale: string) => {
  const data: IDatoOrderingModalProps = useStaticQuery(graphql`
    query OrderingModalMsQuery {
      pickupModal: allDatoCmsPickupModal {
        nodes {
          locale
          franchisee {
            name
            originalId
          }
          title
          legalText
        }
      }
      deliveryModal: allDatoCmsDeliveryModal {
        nodes {
          locale
          franchisee {
            name
            originalId
          }
          title
          legalText
          selectDeliveryOptionLabel
          pickupButtonLabel
          cancelButtonLabel
        }
      }
      directionsModal: allDatoCmsDirectionsModal {
        nodes {
          locale
          franchisee {
            name
            originalId
          }
          title
          legalText
          bodyText
          confirmButtonText
          cancelButtonText
        }
      }
    }
  `);
  const { franchiseeId: SelectedFranchiseeId } = useSelectedFranchiseeId(locale);

  const deliveryModal = franchiseeFilter<IDeliveryModalProps>(data?.deliveryModal.nodes, SelectedFranchiseeId, locale);

  const pickupModal = franchiseeFilter<IPickupModalProps>(data?.pickupModal.nodes, SelectedFranchiseeId, locale);

  const directionsModal = franchiseeFilter<IDirectionsModalProps>(data?.directionsModal.nodes, SelectedFranchiseeId, locale);

  return {
    deliveryModal: {
      title: deliveryModal?.title,
      legalText: deliveryModal?.legalText,
    },
    pickupModal,
    deliveryOptionsModal: deliveryModal,
    directionsModal,
  };
};

export default useOrderingModal;
