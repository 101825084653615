import * as React from 'react';
import Box from '@lce/slice_v2/Layout/Box';
import { SxStyleProp } from 'theme-ui';

export interface IContainerProps {
  width?: number;
  sx?: SxStyleProp;
}

export const Container: React.FC<IContainerProps> = ({ width = '1280px', sx, ...props }) => (
  <Box
    sx={{
      width: '100%',
      mx: 'auto',
      maxWidth: width,
      ...sx,
    }}
    {...props}
  />
);

export default Container;
