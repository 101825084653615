import * as React from 'react';
import { map as _map, find as _find } from 'lodash';
import { Box, Flex } from '@lce/slice_v2';
import { Check, ChevronDown, ChevronUp, Globe } from 'react-feather';

import { useOnClickOutside } from '@lce/intl-util/src/useOnClickOutside';

export const defaultTestId = 'language-selector';

export interface ILanguage {
  locale: string;
  cultureCode: string;
  languageDisplayName: string;
}
export interface ILanguageSelectorProps {
  testId?: string;
  locale: string;
  languages?: ILanguage[];
}

// eslint-disable-next-line max-lines-per-function
const LanguageSelector: React.FC<ILanguageSelectorProps> = ({ testId=defaultTestId, locale, languages }) => {
  const currentLocale = locale?.toLowerCase();
  const selectedLocale = _find(languages, language => language.cultureCode === currentLocale);
  const displayLanguageSelector = languages && languages.length > 1;
  const [displayLanguages, setDisplayLanguages] = React.useState<boolean>(false);

  const handleClick = (cultureCode: string) => {
    const isCurrentLocale = currentLocale === cultureCode;
    
    if (!isCurrentLocale) {
      const newLocale = _find(languages, language => language.cultureCode === cultureCode.toLowerCase())?.cultureCode;
      const newUrl = window.location.href.replace(currentLocale, newLocale || currentLocale);
      window.location.href = newUrl;
    }
  
    setDisplayLanguages(!displayLanguages);
  };
  
  const languageSelectorRef = useOnClickOutside(()=>setDisplayLanguages(false));
  if (!displayLanguageSelector) return null;

  return (
    <Box sx={{ position: 'relative', height: '56px', mb: '16px' }}>
      <Box sx={{ position: ['relative','absolute'], right: '0px' }}>
        <div data-testid="ref" ref={languageSelectorRef} style={{ width: '215px', margin: '0 auto' }}>
          <Flex
            data-testid={testId}
            sx={{
              fontFamily: 'tertiary',
              fontSize: '18px',
              height: '56px',
              justifyContent: ['center', 'flex-end'],
            }}
          >
            <Flex
              data-testid="displayed-language"
              onClick={() => setDisplayLanguages(!displayLanguages)}
              sx={{
                alignItems: 'center',
                backgroundColor: '#fff',
                border: '1px solid #ff6000',
                cursor: 'pointer',
                height: '56px',
                justifyContent: 'space-between',
                position: 'absolute',
                px: '8px',
                width: '215px',
              }}
            >
              <Globe color="#ff6000" />
              <Box
                key={selectedLocale?.languageDisplayName}
                sx={{
                  ml: '-60px',
                  listStyle: 'none',
                }}
              >
                {selectedLocale?.languageDisplayName}
              </Box>
              {displayLanguages && <ChevronUp />}
              {!displayLanguages && <ChevronDown />}
            </Flex>
            {displayLanguages && (
              <Box
                data-testid="locale-list"
                sx={{
                  backgroundColor: '#fff',
                  boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.3)',
                  cursor: 'pointer',
                  position: 'absolute',
                  top: '56px',
                }}
              >
                {_map(languages, language => (
                  <Flex
                    data-testid={language.languageDisplayName}
                    key={language.languageDisplayName}
                    onClick={() => handleClick(language.cultureCode)}
                    sx={{
                      alignItems: 'center',
                      borderBottom: '1px solid #c4c4c4',
                      height: '56px',
                      justifyContent: 'space-between',
                      pr: '12px',
                      width: '215px',
                    }}
                  >
                    <Box
                      sx={{
                        m: ['0 8px', '0 4px', '0 8px'],
                        p: ['8px 0', '4px 0', '8px 0'],
                      }}
                    >
                      {language.languageDisplayName}
                    </Box>
                    {currentLocale === language.cultureCode && <Check color="#ff6000" />}
                  </Flex>
                ))}
              </Box>
            )}
          </Flex>
        </div>
      </Box>
    </Box>
  );
};

export default LanguageSelector;
