import { SxStyleProp } from 'theme-ui';

export const Social: SxStyleProp = {
  alignItems: 'center',
  width: ['100%', '35%', '40%' ],
  justifyContent: 'flex-end',
  flexDirection: ['column', 'row'],
  borderTop: ['2px orange solid', 'none'],
  borderBottom: ['2px orange solid', 'none'],
  py: ['16px', 0],
};

export const SocialText: SxStyleProp = {
  mr: [0, '12px'],
  fontWeight: 'bold',
  pb: ['8px', 0],
  textTransform: 'uppercase',
  fontSize: 10,
  width:['100%'],
  textAlign: ['center','right'],
};
