import * as React from 'react';
import { map as _map } from 'lodash';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import SliceProvider from '@lce/slice_v2/SliceProvider';
import { IDefaultPageProps } from '@lce/intl-types/src/IDefaultPageProps';
import Container from '@lce/intl-ui/src/Layout/Container';
import IePolyfill from '@lce/intl-util/src/IePolyfill';
import Image from '@lce/slice_v2/Elements/Image';
import Box from '@lce/slice_v2/Layout/Box';
import { fontDefinitions, theme } from '@lce/intl-ui/src/theme';
import Button from '@lce/slice_v2/Elements/Button';
import useLayout from '@lce/gatsby-theme-multi-site/src/api/Layout';
import MdxProvider from '@lce/intl-ui/src/Provider/MdxProvider';

import useSiteConfig from '../../api/Config/useSiteConfig';
import { useUpdateFranchiseeId } from '../../api/FranchiseeId';
import useFranchisees from '../../api/FranchiseeId/hooks/useFranchisees';
import Instructions from '../Instructions';

// eslint-disable-next-line max-lines-per-function
const SelectFranchisee: React.FC<IDefaultPageProps> = props => {
  const { locale } = props.pathContext;

  const { landingPage: { headingImage, instructions } } = useSiteConfig(locale);
  const { footer: { copyrightStartingYear, legalText, subLegalText } } = useLayout(locale);
  const [ setFranchiseeId ] = useUpdateFranchiseeId();
  const { franchisees } = useFranchisees(locale);

  if(franchisees.length === 1) {
    setFranchiseeId(franchisees[0].id);
  }
  
  const sortedFranchisees = franchisees.sort((a, b) => a.name.localeCompare(b.name));
  
  return (
    <SliceProvider global={[fontDefinitions]} theme={theme}>
      <Box data-testid="select-franchisee" sx={{ backgroundColor: 'primary', height: '100%', minHeight: '100vh', mb: 3 }}>
        <IePolyfill />
        <Container sx={{ px: '16px', textAlign: 'center' }}>
          {headingImage && <Image alt={headingImage.alt || ''} src={headingImage.url} sx={{ pt: [40, 150], width: '150px', height: 'auto' }}/>}
          <Instructions instructions={instructions} />
          <Box sx={{ maxWidth: '300px', m: 'auto', pb: 40 }}>
            {_map(sortedFranchisees, (franchisee) => (
              <Button
                data-testid={`SelectButton-${franchisee.name}`}
                key={franchisee.id}
                onClick={() => setFranchiseeId(franchisee.id)}
                sx={{
                  px: 5,
                  backgroundColor: 'white',
                  color: 'primary',
                  p: '12px',
                  width: '100%',
                  borderRadius: 0,
                  mt: 16,
                  fontWeight: 'bold',
                  fontSize: 12,
                  cursor: 'pointer',
                }}
              >
                {franchisee.name.toLocaleUpperCase()}
              </Button>
            ))}
          </Box>
        </Container>
      </Box>
      <Container sx={{ display: 'flex', alignItems: 'flex-start', px: '16px' }}>
        <Box sx={{ width: '100%', pb: 4 }}>
          <Box sx={{ fontFamily: 'tertiary', fontSize: 7, display: 'block' }}>
            ©{copyrightStartingYear}-{new Date().getFullYear()} {legalText}
          </Box>
          {subLegalText &&
            <Box sx={{ fontFamily: 'tertiary', fontSize: 7, display: 'block' }}>
              <MdxProvider>
                <MDXRenderer>{subLegalText}</MDXRenderer>
              </MdxProvider>
            </Box>
          }
        </Box>
      </Container>
    </SliceProvider>
  );
};

export default SelectFranchisee;
