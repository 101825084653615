import * as React from 'react';
import { map as _map, find as _find } from 'lodash';

import Box from '@lce/slice_v2/Layout/Box';
import Button from '@lce/slice_v2/Elements/Button';
import Link from '@lce/slice_v2/Elements/Link';
import Image from '@lce/slice_v2/Elements/Image';
import Heading from '@lce/slice_v2/Elements/Heading';

import { ILocationButton } from '@lce/intl-types/src/ILayout';

import LcLogo from '../assets/lc-logo.png';
import RuLogo from '../assets/lc-logo-ru.png';
import Pin from '../assets/locator_pin_black.png';
import * as Styled from './DesktopHeader.style';

export interface INavigationProps {
  locale: string;
  franchiseeSelectorButton?: ILocationButton;
  navigationLinks: INavigationLinkProps[];
}
export interface INavButtonProps {
  background: boolean;
  icon: {
    url: string;
  };
  text: string;
  urlComponent: string;
}

export interface INavigationLinkProps {
  name: string;
  url: string;
  id: string;
}

const DesktopHeader: React.FC<INavigationProps> = ({
  navigationLinks,
  franchiseeSelectorButton,
  locale,
}) => {
  const { onClick, selectedFranchiseeId, franchisees } = franchiseeSelectorButton || {};
  const franchiseeName = _find(franchisees, (franchisee) => franchisee.id === selectedFranchiseeId)?.name;
  const showFranchiseeSelectorButton = selectedFranchiseeId && franchisees && franchisees.length > 1;

  return (
    <Box data-testid="desktop-header" sx={Styled.Header}>
      <Box sx={Styled.HeaderContainer}>
        <Link href="/">
          { 
            locale === 'ru-RU' ? 
              <Image data-testid="russian-image" src={RuLogo} sx={Styled.LCLogo} />
              : 
              <Image data-testid="english-image" src={LcLogo} sx={Styled.LCLogo} />
          }
        </Link>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          {_map(navigationLinks, ({ name, url, id }) => (
            <Link
              href={`/${locale.toLocaleLowerCase()}${url}`}
              key={id}
              sx={Styled.HeaderLink}
              variant="mainNav"
            >
              <Heading as="h2" sx={Styled.LinkText}>
                {name}
              </Heading>
            </Link>
          ))}
          {showFranchiseeSelectorButton && 
            <Button
              data-testid="FranchiseeSelectorButton"
              onClick={onClick} 
              sx={Styled.FranchiseeSelectorButton}
            >
              <Image alt="" src={Pin} sx={Styled.Pin} />
              <Heading as="h2" sx={Styled.LinkText}>
                {franchiseeName}
              </Heading>
            </Button>
          }
        </Box>
      </Box>
    </Box>
  );
};

export default DesktopHeader;
