import * as React from 'react';
import { map as _map } from 'lodash';
import Box from '@lce/slice_v2/Layout/Box';
import Link from '@lce/slice_v2/Elements/Link';
import Heading from '@lce/slice_v2/Elements/Heading';
import { Flex } from '@lce/slice_v2';
import { ILinkNavigation } from '@lce/intl-types/src/ILayout';

import Social from '../Social';

export interface IFooterNavigationProps {
  locale: string;
  navigationLinks: ILinkNavigation[];
  socialMediaLinks: string[];
  socialMediaText: string;
}

const FooterNavigation: React.FC<IFooterNavigationProps> = ({ locale, navigationLinks, socialMediaLinks, socialMediaText }) => {
  const navMargin = socialMediaLinks && socialMediaLinks.length > 0 ? '16px' : 0;
  const navigationBoxSX = socialMediaLinks && socialMediaLinks.length > 0 ? { pb: ['24px', 0], width: ['100%', '65%', '70%'] } : { pb: ['12px', 0], width: '100%' };

  return (
    <Flex sx={{ alignItems: 'center', pb: '12px', flexDirection: ['column', 'row'], my: [navMargin, 0] }}>
      <Box sx={ navigationBoxSX } >
        {_map(navigationLinks, ({ name, url, id }) => (
          <Box
            key={id}
            sx={{
              width: ['100%', 'auto'],
              display: ['inline-block', 'initial'],
              textAlign: 'center',
              '::before': {
                content: ['""', '"|"'],
                m: '0 10px 0 0',
              },
              ':first-of-type': {
                '::before': {
                  content: '""',
                  m: '0',
                },
              },
            }}
          >
            <Link href={`/${locale.toString().toLowerCase()}${url}`} key={id} sx={{ pr: '12px' }}>
              <Heading as='h2' sx={{ display: 'inline-block', m: 0, fontSize: 10 }}>{name}</Heading>
            </Link>
          </Box>
        ))}
      </Box>
      {socialMediaLinks && socialMediaLinks.length > 0 && <Social socialMediaLinks={socialMediaLinks} text={socialMediaText} />}
    </Flex>
  );
};

export default FooterNavigation;
