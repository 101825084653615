import * as parseURL from 'url-parse';

const protocolRegexp = /^https?/;

export const isInternal = (url: string, safeDomains: string[] = []) => {
  const { hostname } = parseURL(url);

  // check if url doesn't start with http(s)
  if (!protocolRegexp.test(url)) {
    return true;
  }

  // check if domain is in safe domains
  return safeDomains.some(domain => domain === hostname);
};

export const canParseURL = (url: string) => {
  try {
    const newUrl = new URL(url);
    return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
  } catch (err) {
    return false;
  }
};
