import * as React from 'react';
import { map as _map } from 'lodash';

import Box from '@lce/slice_v2/Layout/Box';
import Flex from '@lce/slice_v2/Layout/Flex';

import checkCountryLocale from '@lce/intl-util/src/CheckCountryLocale';
import Icon from './Icon';

export interface ISocialIconsProps {
  urls: string[];
}



const SocialIcons: React.FC<ISocialIconsProps> = ({ urls }) => {
  const isRussiaSite = checkCountryLocale('ru-ru');
  return (
    <Flex sx={{
      justifyContent: ['center','flex-end'],
      m: ['0px auto', 0],
      minWidth: `${isRussiaSite ? '120' : '86'}px`,
      width:['100%','auto'],
    }}>
      {
        _map(urls, (mediaUrl, index) => (
          <Box key={ index } sx={{
            mr: '8px',
            ':last-child': {
              mr: 0,
            },
          }}>
            <Icon url={ mediaUrl } />
          </Box>
        ))
      }
    </Flex>
  );
};

export default SocialIcons;
