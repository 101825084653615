import * as React from 'react';

import { INavigation } from '@lce/intl-types/src/ILayout';
import { useMediaQuery } from '@lce/intl-ui/src/Hooks/useMediaQuery';

import DesktopHeader from './Desktop';
import MobileHeader from './Mobile';

export interface IHeaderProps {
  locale: string;
  navigation: INavigation;
}
export interface INavButtonProps {
  background: boolean;
  icon: {
    url: string;
  };
  text: string;
  urlComponent: string;
}

export interface INavigationLinkProps {
  name: string;
  url: string;
  id: string;
}

const Header: React.FC<IHeaderProps> = ({ navigation, locale }) => {
  const isDesktop = useMediaQuery('(min-width: 1280px)');

  return (
    <header data-testid="header-nav">
      {isDesktop ? (
        <DesktopHeader {...navigation} locale={locale} />
      ) : (
        <MobileHeader {...navigation} locale={locale} />
      )}
    </header>
  );
};

export default Header;
