/* eslint-disable max-lines-per-function */
import * as React from 'react';
import Box from '@lce/slice_v2/Layout/Box';
import Link from '@lce/slice_v2/Elements/Link';
import Image from '@lce/slice_v2/Elements/Image';
import IconButton from '@lce/slice_v2/Elements/IconButton';
import { Menu, X } from 'react-feather';

import { ILocationButton } from '@lce/intl-types/src/ILayout';
import LcLogo from '../assets/lc-logo.png';
import RuLogo from '../assets/lc-logo-ru.png';
import * as Styled from './MobileHeader.style';
import Overlay from './Overlay';

export interface INavigationProps {
  locale: string;
  franchiseeSelectorButton?: ILocationButton;
  navigationLinks: INavigationLinkProps[];
}
export interface INavButtonProps {
  background: boolean;
  icon: {
    url: string;
  };
  text: string;
  urlComponent: string;
}

export interface INavigationLinkProps {
  name: string;
  url: string;
  id: string;
}

const MobileHeader: React.FC<INavigationProps> = ({
  navigationLinks,
  franchiseeSelectorButton,
  locale,
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const handleOpen = () => {
    setIsOpen(true);
    document.body.style.overflowY = 'hidden';
  };

  const handleClose = () => {
    setIsOpen(false);
    document.body.style.overflowY = 'visible';
  };

  return (
    <>
      <Box data-testid="mobile-header" sx={Styled.MobileHeader}>
        <Box sx={Styled.HeaderContainer}>
          <Box sx={Styled.MenuButtonContainer}>
            {isOpen ? (
              <IconButton
                data-testid="mobile-close"
                onClick={handleClose}
                sx={{ m: 'auto', width: '100%' }}
              >
                <X color="#fff" size="35" />
              </IconButton>
            ) : (
              <IconButton
                data-testid="mobile-open"
                onClick={handleOpen}
                sx={{ m: 'auto', width: '100%' }}
              >
                <Menu color="#fff" size="35" />
              </IconButton>
            )}
          </Box>
          <Box sx={Styled.LogoContainer}>
            <Link href="/" sx={{ m: 'auto auto 0px auto' }}>
              { 
                locale === 'ru-RU' ? 
                  <Image data-testid="russian-image" src={RuLogo} sx={Styled.MobileLCLogo} />
                  :
                  <Image data-testid="english-image" src={LcLogo} sx={Styled.MobileLCLogo} />
              }
            </Link>
          </Box>
          <Box sx={{ gridArea: '1 / 3 / 2 / 4' }} />
        </Box>
      </Box>

      <Overlay franchiseeSelectorButton={franchiseeSelectorButton} isOpen={isOpen} locale={locale} nav={navigationLinks} />
    </>
  );
};

export default MobileHeader;
