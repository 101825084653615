import * as React from 'react';

import { Box, Button, Flex, Heading, Image, Link, Text } from '@lce/slice_v2';
import { canParseURL } from '@lce/intl-util/src/url-helper';

import LeavingSiteModal from '../Modals/LeavingSiteModal';
import useOrderingModal from '../../../api/OrderingModal';
import SpearImg from './assets/spear.png';
import * as Styled from './StartYourOrder.style';

export interface IStartYourOrder {
  locale: string;
  startYourOrderText: string;
  pickupButtonText: string;
  pickupButtonUrl: string;
  deliveryButtonText: string;
  deliveryUrl: string;
}

// eslint-disable-next-line max-lines-per-function
const StartYourOrder: React.FC<IStartYourOrder> = ({
  locale,
  startYourOrderText,
  pickupButtonText,
  pickupButtonUrl,
  deliveryButtonText,
  deliveryUrl,
}) => {
  const [isOpenDelivery, setIsOpenDelivery] = React.useState<boolean>(false);
  const [isOpenPickup, setIsOpenPickup] = React.useState<boolean>(false);

  const hasPickupButton = pickupButtonText && pickupButtonUrl;
  const hasDeliveryButton = deliveryButtonText && deliveryUrl;
  const hasButton = hasPickupButton || hasDeliveryButton;
  const hasBothButtons = hasPickupButton && hasDeliveryButton;

  const modalData = useOrderingModal(locale);

  if (!hasButton) {
    return null;
  }

  const isValidPickupUrl = canParseURL(pickupButtonUrl);
  const isValidDeliveryUrl = canParseURL(deliveryUrl);

  return (
    <Flex data-testid="startyourorder-banner" sx={Styled.Wrapper}>
      <Heading sx={Styled.StartYourOrderText}>{startYourOrderText}</Heading>
      <Box sx={hasBothButtons ? Styled.ButtonContainer : Styled.OnlyOneButtonContainer}>
        {hasPickupButton && ( isValidPickupUrl ? (
          <>
            <Button
              data-testid="startyourorder-banner-pickupLink"
              onClick={() => setIsOpenPickup(true)}
              sx={Styled.Button}
              variant="primary"
            >
              {pickupButtonText}
            </Button>
            <LeavingSiteModal
              content={modalData.deliveryModal}
              isOpen={isOpenPickup}
              onClose={() => setIsOpenPickup(false)}
              testId="startyourorder-banner-pickupModal"
              url={pickupButtonUrl}
            >
              {pickupButtonText}
            </LeavingSiteModal>
          </>
        ) : (
          <Link href={`/${locale.toLocaleLowerCase()}/${pickupButtonUrl}`} sx={Styled.Link} variant="skeleton">
            <Text sx={Styled.LinkText}>{pickupButtonText}</Text>
          </Link>
        ))}
        {hasBothButtons && (<Image src={SpearImg} sx={Styled.SpearDivider} />)}
        {hasDeliveryButton && ( isValidDeliveryUrl ? (
          <>
            <Button
              data-testid="startyourorder-banner-deliveryLink"
              onClick={() => setIsOpenDelivery(true)}
              sx={Styled.Button}
              variant="primary"
            >
              {deliveryButtonText}
            </Button>
            <LeavingSiteModal
              content={modalData.deliveryModal}
              isOpen={isOpenDelivery}
              onClose={() => setIsOpenDelivery(false)}
              testId="startyourorder-banner-deliveryModal"
              url={deliveryUrl}
            >
              {pickupButtonText}
            </LeavingSiteModal>
          </>
        ) : (
          <Link href={`/${locale.toLocaleLowerCase()}/${deliveryUrl}`} sx={Styled.Link} variant="skeleton">
            <Text sx={Styled.LinkText}>{deliveryButtonText}</Text>
          </Link>
        ))}
      </Box>
    </Flex>
  );
};

export default StartYourOrder;
